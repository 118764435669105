import { extend, localize, setInteractionMode } from 'vee-validate'
import { email, required, image, min, confirmed, max, regex } from 'vee-validate/dist/rules'

setInteractionMode('lazy')

extend('required', required)
extend('email', email)
extend('image', image)
extend('min', min)
extend('max', max)
extend('regex', regex)
extend('confirmed', confirmed)

extend('max_files', (value, length) => {
  if (value.length <= length) {
    return true
  }
  return `You try to load more than ${length} files`
})

localize('ru')
