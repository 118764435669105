<template>
  <v-dialog scrollable content-class="rounded-0" v-model="open">
        <v-card tile class="pa-0">
          <v-card-title class="elevation-5" :style="`z-index: 2; height: ${$vuetify.breakpoint.height / 12}px`">
            <span>{{ $t('forms.section.edit.image.choose') }}</span>
            <v-spacer/>
            <v-btn color="primary" dark @click="closeSectionImageForEdit" small fab>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0 mt-6 pr-6 pl-6">
            <v-row>
              <v-col col="12" lg="5" offset-lg="7" class="pr-12">
                <v-text-field v-model="image"
                              :placeholder="$t('forms.section.edit.image.search.placeholder')"
                              :label="$t('forms.section.edit.image.search.label')"
                              prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
            <v-item-group>
              <v-row class="lbm_adaptive-container">
                <v-col @click="chooseImage(image.id)"
                       class="lbm_adaptive-container__item" cols="6" lg="3" v-for="(image, key) in _images" :key="key">
                    <v-item v-slot="{active, toggle}">
                      <div style="position:relative;">
                        <v-img @error="removeFromItems(image)"
                               @click="toggle"
                               class="d-flex justify-center align-center lbm_section-img content"
                               :class="{'lbm_choose': active}"
                               :src="image.src"
                               :lazy-src="image.src">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                          <v-scroll-y-transition>
                            <div
                              v-if="active"
                              class="display-3 flex-grow-1 text-center"
                            >
                              <v-icon color="primary" size="64">mdi-check-circle</v-icon>
                            </div>
                          </v-scroll-y-transition>
                        </v-img>
                        <v-btn @click="editImage(image.src, image.id)" style="position: absolute; right: 5%; top: 5%" fab x-small dark color="primary">
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </v-item>
                </v-col>
                <v-col cols="12">
                  <v-card flat tile min-height="1rem" :loading="pending" v-intersect="infiniteScrolling"></v-card>
                </v-col>
              </v-row>
            </v-item-group>
          </v-card-text>
          <v-card-actions :style="`height: ${$vuetify.breakpoint.height / 12}px`">
            <v-spacer/>
            <v-btn tile color="warning" dark
                   @click="createImage">{{ $t('buttons.add') }}</v-btn>
            <v-btn tile color="primary"
                   @click="submit">{{ $t('buttons.update') }}</v-btn>
            <v-btn dark tile color="grey"
                   @click="closeSectionImageForEdit">{{ $t('buttons.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
  </v-dialog>
</template>

<script>
import { SectionInterface } from '@/interfaces/section.interface'

export default {
  name: 'EditSectionImageDialogComponent',
  data () {
    return {
      section: new SectionInterface(),
      open: false,
      image: null,
      images: [],
      type: 'update',
      page: 1,
      pending: false
    }
  },
  created () {
    this.fetchData()
  },
  mounted () {
    this.$root.$on('open_image_dialog', (section, type) => {
      this.section = section
      this.type = type
      this.open = true
    })
    this.$root.$on('close_image_dialog', () => {
      this.closeSectionImageForEdit()
    })
  },
  methods: {
    closeSectionImageForEdit () {
      this.open = false
    },
    chooseImage (id) {
      this.section.image_id = id
    },
    submit () {
      switch (this.type) {
        case 'create':
          this.$root.$emit('create_section', this.section)
          break
        default:
          this.$root.$emit('update_section', this.section)
          break
      }
    },
    removeFromItems (item) {
      const index = this.images.indexOf(item)
      this.images.splice(index, 1)
    },
    async fetchData () {
      await this.$http.get(this.url).then(response => {
        this.images = response.data.images.data
      })
    },
    infiniteScrolling (entries, observer, isIntersecting) {
      this.pending = true
      setTimeout(() => {
        this.page++
        this.$http
          .get(this.url)
          .then(response => {
            response.data.images.data.forEach(item => this.images.push(item))
            this.pending = false
          })
          .catch(err => {
            console.log(err)
          })
      }, 500)
    },
    createImage () {
      this.closeSectionImageForEdit()
      this.$router.push({ name: 'admin.image-editor' }).then(() => {})
    },
    editImage (src, id) {
      this.closeSectionImageForEdit()
      this.$http.post('api/v2/get-image-data-url',
        {
          src: src
        }
      )
        .then(response => {
          window.sessionStorage.setItem('image', response.data.success)
          window.sessionStorage.setItem('image_id', id)
          this.$router.push({ name: 'admin.image-editor', params: { src: response.data.success, id: id } })
        })
    }
  },
  computed: {
    url () {
      return `api/v2/auth/images?page=${this.page}`
    },
    _images () {
      if (this.image) {
        return this.images.filter((item) => {
          return item.src.includes(this.image)
        })
      } else {
        return this.images
      }
    }
  }
}
</script>

<style scoped>

</style>
