export default {
  SET_ROUTE (state, route) {
    state.route = route
  },
  SET_DRAWER (state, drawer) {
    state.drawer = drawer
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_USER (state, user) {
    state.user = user
  },
  SET_ALERT (state, alert) {
    state.alert = alert
  },
  SET_AUTHORIZATION (state, authorization) {
    state.authorization = authorization
  },
  SET_CONFIRMATION (state, confirmation) {
    state.confirmation = confirmation
  },
  SET_OVERLAY (state, overlay) {
    state.overlay = overlay
  },
  SET_ID (state, id) {
    state.id = id
  },
  SET_IMG (state, img) {
    state.img = img
  },
  SET_IMAGE_DIALOG (state, status) {
    state.imageDialog = status
  }
}
