var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":_vm.$vuetify.breakpoint.width / 1.3,"content-class":"rounded-0"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"elevation-3"},[_vm._v(" "+_vm._s(_vm.$t('forms.section.edit.icon.title'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","fab":""},on:{"click":_vm.closeIconChoosing}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":_vm.pack === 'mdi' ? 'primary' : 'warning',"tile":""},on:{"click":function($event){return _vm.setPack('mdi')}}},[_vm._v("Material Design Icons")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":_vm.pack === 'kitchen' ? 'primary' : 'warning',"tile":""},on:{"click":function($event){return _vm.setPack('kitchen')}}},[_vm._v("Kitchen Icons")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":_vm.pack === 'special' ? 'primary' : 'warning',"tile":""},on:{"click":function($event){return _vm.setPack('special')}}},[_vm._v("Special Icons")]),_c('v-spacer'),_c('v-text-field',{attrs:{"placeholder":_vm.$t('forms.section.edit.icon.search.placeholder'),"label":_vm.$t('forms.section.edit.icon.search.label'),"prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}})],1)],1),_c('v-item-group',[_c('v-virtual-scroll',{staticClass:"d-flex justify-center",attrs:{"bench":1,"items":_vm.chunkIcons,"height":"400","item-height":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pa-3"},_vm._l((item),function(icon){return _c('v-col',{key:icon,attrs:{"cols":"1"}},[(icon.includes('lbm'))?[_c('v-item',{key:("icon-" + icon),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-center align-center",attrs:{"fab":""},on:{"click":function($event){toggle; _vm.chooseIcon(icon)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(icon.replace('lbm', '').replace(/\-/gi, ' ').replace(/[0-9]/gi, '')))])])]}}],null,true)})]:_vm._e(),(icon.includes('mdi'))?[_c('v-item',{key:("icon-" + icon),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-center align-center",attrs:{"fab":""},on:{"click":function($event){toggle; _vm.chooseIcon(icon)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(icon)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(icon.replace('mdi', '').replace(/\-/gi, ' ')))])])]}}],null,true)})]:_vm._e(),(icon.includes('special'))?[_c('v-item',{key:("icon-" + icon),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-center align-center",attrs:{"fab":""},on:{"click":function($event){toggle; _vm.chooseIcon(icon)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(icon)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(icon.replace('special', '').replace(/\-/gi, ' ')))])])]}}],null,true)})]:_vm._e()],2)}),1)]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.$store.getters.GET_LOADING,"tile":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('buttons.update')))]),_c('v-btn',{attrs:{"dark":"","tile":"","color":"grey"},on:{"click":_vm.closeIconChoosing}},[_vm._v(_vm._s(_vm.$t('buttons.cancel')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }