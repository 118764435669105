<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <div class="d-flex align-center">
      <v-img
        alt="Lebensmittel Logo"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="@/assets/logo.svg"
        width="120"
        @click="$router.push({name: 'main'}).catch(() => {})"
      />
    </div>

    <v-spacer></v-spacer>

    <v-btn
      @click="toggleDrawer"
      icon
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBarComponent',
  methods: {
    toggleDrawer () {
      this.$store.dispatch('setDrawer', true)
    }
  }
}
</script>

<style scoped>

</style>
