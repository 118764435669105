export default {
  route: false,
  drawer: false,
  loading: false,
  alert: null,
  user: null,
  authorization: null,
  confirmation: false,
  overlay: false,
  id: null,
  img: null,
  imageDialog: false
}
