<template>
  <v-dialog v-model="show"
            scrollable
            :width="$vuetify.breakpoint.width / 1.4"
            :height="$vuetify.breakpoint.height / 1.4"
            content-class="rounded-0"
            @click:outside="close">
    <v-card tile>
      <v-card-title>
        <span>Edit {{ form.title }}</span>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="addNewObserver" v-slot="{ passes }">
          <v-form @submit.prevent="passes( submit )">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ml-0 pl-0">
                <v-text-field label="Edit title"
                              placeholder="Please edit recipe title"
                              clearable
                              v-model="form.title"></v-text-field>
                <v-dialog
                  content-class="rounded-0"
                  ref="dialog"
                  v-model="timeModal"
                  :return-value.sync="form.time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.time"
                      label="Edit preparation time"
                      placeholder="Please set preparation time"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    class="rounded-0"
                    no-title
                    v-if="timeModal"
                    v-model="form.time"
                    format="24hr"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="timeModal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(form.time)"
                    >
                      Set
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
                <template v-if="form.images.length > 0">
                  <label>Already attached images</label>
                  <div class="d-flex pl-0 pr-0 my-4">
                    <v-hover v-for="(image, i) in form.images" :key="image.id" v-slot:default="{ hover }">
                      <v-img style="position: relative" class="mr-2" :aspect-ratio="16/9"
                             :src="image.link"
                             :max-width="($vuetify.breakpoint.width / 1.4) / 5">
                        <div class="d-flex justify-end align-center"
                             style="width: 30%; position: absolute; right: 2px; top: 2px">
                          <v-btn :class="{'mr-1 lbm_img-icon-btn': true, 'lbm_hover': hover}" x-small dark color="error" fab>
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn @click="deleteAttachment(i, image.id)" :class="{'lbm_img-icon-btn': true, 'lbm_hover': hover}" x-small dark color="error" fab>
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-img>
                    </v-hover>
                  </div>
                </template>
                <v-file-input ref="images"
                              @change="chooseFiles"
                              label="Edit recipe images"
                              placeholder="Please edit recipe images"
                              prepend-icon=""
                              multiple
                              accept="image/*"
                              v-model="newImages">
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" label small color="warning">
                      {{ text }}
                    </v-chip>
                    <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
                          + {{ newImages.length - 2 }} {{ newImages.length - 2 > 1 ? 'images' : 'image' }}
                        </span>
                  </template>
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-image-multiple</v-icon>
                  </template>
                </v-file-input>
                <div class="my-5">
                  <div v-for="(ingredient, i) in ingredients"
                       class="d-lg-flex align-lg-center justify-lg-space-between"
                       :key="i">
                    <v-text-field
                      label="Edit ingredient name"
                      placeholder="Please edit ingredient name"
                      type="text"
                      class="mr-lg-1" v-model="ingredient.name">
                    </v-text-field>
                    <v-text-field
                      label="Edit ingredient amount"
                      placeholder="Please edit ingredient amount (in decimal form)"
                      type="number"
                      class="ml-lg-1" v-model="ingredient.amount">
                    </v-text-field>
                    <v-select
                      :items="amounts"
                      label="Edit kind of amount"
                      placeholder="Please edit kind of amount"
                      hint="Please edit kind of amount"
                      type="number"
                      class="ml-lg-1" v-model="ingredient.kind">
                    </v-select>
                    <v-btn class="ml-lg-3"
                           @click="removeIngredient(i, ingredient.id)" fab x-small color="error">
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex align-center mb-lg-4">
                    <v-btn @click="addIngredient" fab x-small color="success">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <label class="ml-2">Add ingredient</label>
                  </div>
                </div>
                <v-divider class="mt-n1"></v-divider>
                <div class="my-5">
                  <div v-for="(step, i) in steps"
                       class="d-lg-flex align-lg-center justify-lg-space-between"
                       :key="i">
                    <v-textarea
                      label="Edit step description"
                      placeholder="Please edit description for every step of preparation"
                      v-model="step.step">
                    </v-textarea>
                    <v-btn class="ml-lg-3"
                           @click="removeStep(i, step.id)" fab x-small color="error">
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex align-center mb-lg-4">
                    <v-btn @click="addStep" fab x-small color="success">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <label class="ml-2">Add step</label>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="mt-5">
                  <v-select :items="group"
                            v-model="form.group"
                            label="Edit recipe group"
                            placeholder="Please choose recipe group"></v-select>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile
                     color="error"
                     type="button" @click="cancel">Cancel</v-btn>
              <v-btn tile
                     :loading="$store.getters.GET_LOADING"
                     color="success"
                     type="submit">Update</v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppEditRecipeDialogComponent',
  data () {
    return {
      timeModal: false,
      amounts: [
        {
          text: 'piece'
        },
        {
          text: 'spoon'
        },
        {
          text: 'g'
        },
        {
          text: 'kg'
        },
        {
          text: 'plate'
        },
        {
          text: 'cup'
        },
        {
          text: 'ml'
        },
        {
          text: 'l'
        }
      ],
      group: [
        {
          text: 'Fish',
          value: 'fish'
        },
        {
          text: 'Meat',
          value: 'meat'
        },
        {
          text: 'Vegetables',
          value: 'vegetables'
        },
        {
          text: 'Fruits',
          value: 'fruits'
        },
        {
          text: 'Soup',
          value: 'soup'
        }
      ],
      newImages: [],
      attachments: []
    }
  },
  mounted () {
    this.$root.$on('remove-image', (id, index) => {
      this.$http.delete(`api/v2/auth/attachment/${id}`)
        .then(() => {
          this.$store.dispatch('setConfirmation', { show: false, action: null })
          this.form.images.splice(index, 1)
        })
        .finally(() => {
          this.$root.$emit('reload-recipes')
        })
    })
  },
  methods: {
    close () {
      this.$store.dispatch('setRecipeEditDialog', false)
      this.$store.dispatch('setRecipeForEdit', null)
      this.form = this.$worker.resetForm(this.form)
    },
    submit () {
      this.$store.dispatch('setLoading', true)
      this.form.attachments = this.attachments
      this.$http.patch(`api/v2/auth/recipe/${this.form.id}`, this.form)
        .then(response => {
          this.$store.dispatch('setRecipes', response.data.recipes)
          this.close()
        })
        .finally(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
    cancel () {
      this.close()
    },
    addIngredient () {
      this.form.ingredients.push(
        {
          name: '',
          amount: ''
        }
      )
    },
    addNutrient () {
      this.form.nutrients.push(
        {
          name: '',
          amount: ''
        }
      )
    },
    addStep () {
      this.form.steps.push(
        {
          description: ''
        }
      )
    },
    removeIngredient (index, id) {
      this.form.ingredients.splice(index, 1)
      if (id) {
        this.$http.delete(`api/v2/auth/ingredient/${id}`)
          .finally(() => {
            this.$root.$emit('reload-recipes')
          })
      }
    },
    removeNutrient (index, id) {
      this.form.nutrients.splice(index, 1)
      if (id) {
        this.$http.delete(`api/v2/auth/nutrient/${id}`)
          .finally(() => {
            this.$root.$emit('reload-recipes')
          })
      }
    },
    removeStep (index, id) {
      this.form.steps.splice(index, 1)
      if (id) {
        this.$http.delete(`api/v2/auth/step/${id}`)
          .finally(() => {
            this.$root.$emit('reload-recipes')
          })
      }
    },
    chooseFiles (files) {
      files.forEach(file => {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.attachments.push(
            {
              image: reader.result
            }
          )
        }
        reader.readAsDataURL(file)
      })
    },
    deleteAttachment (index, id) {
      this.$store.dispatch('setConfirmation', { show: true, action: 'remove-image', id: id, index: index })
    }
  },
  computed: {
    show () {
      return this.$store.getters.GET_RECIPE_EDIT_DIALOG
    },
    ingredients () {
      return this.form.ingredients
    },
    nutrients () {
      return this.form.nutrients
    },
    steps () {
      return this.form.steps
    },
    form: {
      get () {
        return this.$store.getters.GET_RECIPE_FOR_EDIT ? this.$store.getters.GET_RECIPE_FOR_EDIT : {}
      },
      set (key, value) {
        this.form[key] = value
      }
    }
  }
}
</script>

<style scoped>

</style>
