<template>
  <v-snackbar tile
              vertical
              :width="$vuetify.breakpoint.width / 3"
              color="warning"
              timeout="-1" top multi-line v-model="show">
    <v-card flat tile color="transparent">
      <v-card-text style="color: #333333">
        {{ $t('removing.text') }}
      </v-card-text>
    </v-card>
    <template v-slot:action>
      <v-btn tile
             :loading="$store.getters.GET_LOADING"
             color="error"
             dark
             @click="confirmAction">{{ $t('buttons.yes') }}</v-btn>
      <v-btn tile class="ml-3"
             color="primary"
             dark
             @click="closeConfirm">{{ $t('buttons.no') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AppConfirmationDialogComponent',
  methods: {
    closeConfirm () {
      this.$store.dispatch('setConfirmation', { show: false, action: null })
    },
    confirmAction () {
      this.$root.$emit(this.action, this.id, this.index)
    }
  },
  computed: {
    show () {
      return this.$store.getters.GET_CONFIRMATION.show
    },
    action () {
      return this.$store.getters.GET_CONFIRMATION.action
    },
    id () {
      return this.$store.getters.GET_CONFIRMATION.id
    },
    index () {
      return this.$store.getters.GET_CONFIRMATION.index
    }
  }
}
</script>

<style scoped>

</style>
