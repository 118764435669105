import axios from 'axios'
const Translator = {
  install (Vue) {
    Vue.prototype.$translator = {
      translate: ($filepath, $langModel) => {
        const data = new FormData()
        data.append('model_id', $langModel)
        data.append('file', $filepath)

        const config = {
          method: 'post',
          url: `${process.env.VUE_APP_IBM_URL}/documents?version=2018-05-01`,
          headers: {
            Authorization: `Basic ${process.env.VUE_APP_IBM_API_KEY}`
          },
          data: data
        }

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
  }
}
export default (Translator)
