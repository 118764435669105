import Vue from 'vue'
import _ from 'lodash'

const truncate = require('truncate-html')

Vue.filter('capitalize', (value) => {
  if (value.includes('vitamin')) {
    const split = value.replace('_', ' ').split(' ')
    const vitamin = _.capitalize(split[0])
    const name = _.toUpper(split[1])
    return `${vitamin} ${name}`
  } else {
    return _.capitalize(value)
  }
})

Vue.filter('countWords', (value) => {
  if (value) {
    const tmp = document.createElement('div')
    tmp.innerHTML = value
    return tmp.textContent.match(/\S+/g).length || tmp.innerText.match(/\S+/g).length
  } else {
    return 0
  }
})

Vue.filter('truncateHTML', (value, length = 50) => {
  return truncate.default(value, length, { byWords: true })
})

Vue.filter('truncate', (value, length) => {
  if (value.length <= length) {
    return value
  }
  return `${value.slice(0, length)}...`
})

Vue.filter('isObjectPropertyNull', (obj) => {
  return Object.values(obj).every(prop => prop === null)
})

Vue.filter('inArray', (value, array) => {
  return array.includes(value)
})
