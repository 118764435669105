export default function auth ({ next, store }) {
  if (store.getters.GET_AUTHORIZATION) {
    if (new Date().getTime() > store.getters.GET_AUTHORIZATION._expires_at) {
      store.dispatch('setUser', null)
      store.dispatch('setAuthorization', null)
      return next({
        name: 'main'
      })
    }
    switch (store.getters.GET_USER.role) {
      case 'admin':
        return next({
          name: 'admin.dashboard'
        })
      case 'user':
        return next({
          name: 'user.dashboard'
        })
    }
  }
  return next()
}
