import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import HttpService from '@/plugins/http'
import WorkerService from '@/plugins/worker'
import VueCookies from 'vue-cookies'
import Translator from '@/plugins/translator'
const vuetify = new Vuetify()

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})
Vue.use(HttpService)
Vue.use(WorkerService)
Vue.use(VueCookies)
Vue.use(Translator)
