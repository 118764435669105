<template>
  <v-dialog scrollable :max-width="$vuetify.breakpoint.width / 1.2" content-class="rounded-0" v-model="openDialog">
    <v-card tile>
      <v-card-title class="elevation-3">
        <span>Add new section</span>
        <v-spacer/>
        <v-btn @click="closeDialog" color="primary" dark small fab>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-n1">
          <v-col :cols="sections.length > 0 ? 6 : 12">
            <validation-observer ref="observer" v-slot="{ passes }">
              <v-form id="creationForm" @submit.prevent="passes( submit )">
                <v-checkbox v-model="section.food"
                            color="primary"
                            label="Is Food?"></v-checkbox>
                <validation-provider name="Section name" rules="required" v-slot="{  errors }">
                  <v-text-field placeholder="Enter section name" label="Please, add section name"
                                :error-messages="errors" v-model="section.name"></v-text-field>
                </validation-provider>
                <validation-provider name="Section route" rules="required" v-slot="{  errors }">
                  <v-text-field placeholder="Enter section route" label="Please, add section route"
                                :error-messages="errors" v-model="section.route"></v-text-field>
                </validation-provider>
                <v-btn @click="chooseImage" class="my-4 mr-1" tile color="primary">Please, choose section image</v-btn>
                <v-btn @click="chooseIcon" class="my-4 ml-1" tile color="primary">Please, choose section icon</v-btn>
                <tiptap-vuetify placeholder="Please, enter content for section" :card-props="{ tile: true }" v-model="section.content.content"
                                :extensions="extensions"></tiptap-vuetify>
              </v-form>
            </validation-observer>
          </v-col>
          <v-col v-if="sections.length > 0" cols="6">
              <v-treeview
                v-model="selected"
                :active.sync="active"
                selectable
                selection-type="independent"
                :items="items"
                :load-children="getSections"
                :open.sync="open"
                on-icon="mdi-check-circle"
                off-icon="mdi-check-circle-outline"
                expand-icon=""
                activatable
                color="warning"
                open-on-click
                transition
              >
              </v-treeview>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn form="creationForm" tile color="primary" type="submit">Create</v-btn>
        <v-btn dark tile color="grey" @click="closeDialog">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import { SectionInterface } from '@/interfaces/section.interface'

export default {
  name: 'AddNewSectionDialogComponent',
  components: {
    TiptapVuetify
  },
  data () {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      selected: [],
      active: [],
      open: [],
      sections: [],
      section: new SectionInterface()
    }
  },
  mounted () {
    this.$root.$on('create_section', (section) => {
      this.section = section
      this.$root.$emit('close_image_dialog')
      this.$root.$emit('close_icon_dialog')
    })
  },
  methods: {
    closeDialog () {
      this.$store.dispatch('setAddSubsection', false)
    },
    async getSections () {
      await this.$http.get('api/v2/auth/sections')
        .then((response) => {
          this.sections = response.data.sections
        })
    },
    submit () {
      console.log(this.selected)
      this.section.created_content = this.section.content.content
      this.section.sectionable_id = this.selected.length > 0 ? this.selected[0] : null
      this.$http.post('api/v2/auth/sections', this.section)
        .then((response) => {
          console.log(response.data)
        })
    },
    chooseImage () {
      this.$root.$emit('open_image_dialog', this.section, 'create')
    },
    chooseIcon () {
      this.$root.$emit('open_icon_dialog', this.section, 'create')
    }
  },
  computed: {
    openDialog () {
      return this.$store.getters.GET_ADDSUBSECTION
    },
    items () {
      return [{
        name: 'Sections',
        children: this.sections
      }]
    }
  }
}
</script>

<style scoped>

</style>
