import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard'
import store from '@/store'
import auth from '@/router/middlewares/auth'
import admin from '@/router/middlewares/admin'
import middlewarePipeline from '@/router/middlewarePipeline'
import loggedIn from '@/router/middlewares/loggedIn'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('../views/Login.vue'),
    meta: {
      middleware: [
        loggedIn
      ]
    }
  },
  {
    path: '/admin',
    component: Dashboard,
    redirect: '/admin/dashboard',
    meta: {
      middleware: [
        auth,
        admin
      ]
    },
    children: [
      {
        path: '/admin/dashboard',
        name: 'admin.dashboard',
        component: () => import('../views/admin/Statistic.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/dashboard/menu',
        name: 'admin.menu',
        component: () => import('../views/admin/Menu.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/dashboard/footer',
        name: 'admin.footer',
        component: () => import('../views/admin/Footer.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/dashboard/recipes/:id',
        name: 'admin.recipes',
        component: () => import('../views/admin/Recipes.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/dashboard/section/:id',
        name: 'admin.sections',
        component: () => import('../views/admin/Section.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/dashboard/create/section',
        name: 'admin.create.section',
        component: () => import('../views/admin/Create.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/dashboard/create/recipe',
        name: 'admin.create.recipe',
        component: () => import('../views/admin/Create.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/image-editor',
        name: 'admin.image-editor',
        component: () => import('../views/admin/Editor.vue'),
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/images',
        name: 'admin.images',
        component: () => import('../views/admin/Images.vue'),
        props: true,
        meta: {
          middleware: [
            auth
          ]
        }
      }
    ]
  },
  {
    path: '/user',
    component: Dashboard,
    name: 'user.dashboard',
    redirect: '/user/food',
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: '/user/food',
        name: 'user.food',
        component: () => import('../views/user/Food.vue'),
        meta: {
          middleware: [
            auth
          ]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('setOverlay', true).then()
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.afterEach((to, from) => {
  setTimeout(() => {
    store.dispatch('setOverlay', false).finally()
  }, 1500)
})

export default router
