export default {
  GET_DRAWER (state) {
    return state.drawer
  },
  GET_LOADING (state) {
    return state.loading
  },
  GET_USER (state) {
    return state.user
  },
  GET_ALERT (state) {
    return state.alert
  },
  GET_AUTHORIZATION (state) {
    return state.authorization
  },
  GET_CONFIRMATION (state) {
    return state.confirmation
  },
  GET_OVERLAY (state) {
    return state.overlay
  },
  GET_ID (state) {
    return state.id
  },
  GET_IMG (state) {
    return state.img
  },
  GET_IMAGE_DIALOG (state) {
    return state.imageDialog
  }
}
