const WorkerService = {
  /* eslint-disable no-prototype-builtins */
  install (Vue) {
    Vue.prototype.$worker = {
      resetForm: (object) => {
        for (const property in object) {
          if (object.hasOwnProperty(property)) {
            if (Array.isArray(object[property])) {
              object[property] = []
            } else {
              object[property] = null
            }
          }
        }
        return object
      }
    }
  }
}

export default (WorkerService)
