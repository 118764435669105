<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="d-flex align-center ma-0 pa-0">
      <v-autocomplete
        v-model="ingredient.name"
        :items="list"
        :loading="loading"
        :search-input.sync="search"
        item-text="pagetitle"
        placeholder="Please add ingredient name"
        label="Add ingredient name"
        no-data-text="Nothing found"
        clearable>
      </v-autocomplete>
      <v-text-field
        label="Add ingredient amount"
        placeholder="Please add ingredient amount"
        type="number"
        class="ml-lg-1" v-model="ingredient.amount">
      </v-text-field>
      <v-select
        :items="amounts"
        label="Add choose kind of amount"
        placeholder="Please choose kind of amount"
        hint="Please choose kind of amount"
        type="number"
        class="ml-lg-1" v-model="ingredient.kind">
      </v-select>
      <v-btn class="ml-lg-3"
             @click="removeIngredient(index)" fab x-small color="error">
        <v-icon small>mdi-minus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'AppAutocompleteIngredientSearchComponent',
  props: {
    index: Number,
    ingredient: Object
  },
  data () {
    return {
      search: '',
      list: [],
      loading: false,
      amounts: [
        {
          text: 'piece'
        },
        {
          text: 'spoon'
        },
        {
          text: 'g'
        },
        {
          text: 'kg'
        },
        {
          text: 'plate'
        },
        {
          text: 'cup'
        },
        {
          text: 'ml'
        },
        {
          text: 'l'
        }
      ]
    }
  },
  watch: {
    search (search) {
      if (!search) {
        return
      }
      this.debounceSearch()
    }
  },
  methods: {
    removeIngredient () {
      this.$root.$emit('removeIngredient', this.index)
    },
    async searching () {
      if (!this.search) {
        this.list = []
        this.search = ''
      }
      if (this.loading) {
        return
      }
      this.loading = true

      await this.$http.get(`api/v2/auth/food/${this.search}`)
        .then((response) => {
          this.list = response.data.food
        })
        .finally(() => {
          this.loading = false
        })
    },
    debounceSearch: _.debounce(function () {
      this.searching()
    }, 200),
    debounceSetAmount: _.debounce(function () {
      this.$store.dispatch('setAmounts', this.amount)
    }, 1000)
  }
}
</script>

<style scoped>

</style>
