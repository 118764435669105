<template>
  <v-dialog v-model="show"
            :width="$vuetify.breakpoint.width / 1.6"
            :height="$vuetify.breakpoint.height / 1.6"
            content-class="rounded-0"
            @click:outside="close">
    <v-card tile>
      <v-card-title>
        <span></span>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="addNewObserver" v-slot="{ passes }">
          <v-form @submit.prevent="passes( submit )">
            <v-text-field dense v-model="form.title"></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile
                     color="error"
                     type="button" @click="cancel">Cancel</v-btn>
              <v-btn tile
                     color="success"
                     type="submit">Add new</v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppAddFoodDialogComponent',
  data () {
    return {
      form: {}
    }
  },
  methods: {
    close () {
      this.$store.dispatch('setFoodDialog', false)
    },
    submit () {

    },
    cancel () {
      this.$store.dispatch('setFoodDialog', false)
    }
  },
  computed: {
    show () {
      return this.$store.getters.GET_FOOD_DIALOG
    }
  }
}
</script>

<style scoped>

</style>
