
export default {
  setRoute ({ commit }, data) {
    commit('SET_ROUTE', data)
  },
  setDrawer ({ commit }, data) {
    commit('SET_DRAWER', data)
  },
  setLoading ({ commit }, data) {
    commit('SET_LOADING', data)
  },
  setUser ({ commit }, data) {
    commit('SET_USER', data)
  },
  setAlert ({ commit }, data) {
    commit('SET_ALERT', data)
  },
  setAuthorization ({ commit }, data) {
    commit('SET_AUTHORIZATION', data)
  },
  setRecipes ({ commit }, data) {
    commit('SET_RECIPES', data)
  },
  setConfirmation ({ commit }, data) {
    commit('SET_CONFIRMATION', data)
  },
  setOverlay ({ commit }, data) {
    commit('SET_OVERLAY', data)
  },
  setId ({ commit }, data) {
    commit('SET_ID', data)
  },
  setImg ({ commit }, data) {
    commit('SET_IMG', data)
  },
  setImageDialog ({ commit }, data) {
    commit('SET_IMAGE_DIALOG', data)
  }
}
