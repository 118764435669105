<template>
  <v-dialog scrollable :max-width="$vuetify.breakpoint.width / 1.3" content-class="rounded-0" v-model="open">
    <v-card tile>
      <v-card-title class="elevation-3">
        {{ $t('forms.section.edit.icon.title') }}
        <v-spacer/>
        <v-btn color="primary" dark @click="closeIconChoosing" small fab>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-row class="mb-3">
          <v-col cols="12" class="d-flex align-center">
            <v-btn class="mr-1"
                   @click="setPack('mdi')"
                   :color="pack === 'mdi' ? 'primary' : 'warning'"
                   tile>Material Design Icons</v-btn>
            <v-btn class="ml-1"
                   @click="setPack('kitchen')"
                   :color="pack === 'kitchen' ? 'primary' : 'warning'"
                   tile>Kitchen Icons</v-btn>
            <v-btn class="ml-1"
                   @click="setPack('special')"
                   :color="pack === 'special' ? 'primary' : 'warning'"
                   tile>Special Icons</v-btn>
            <v-spacer/>
            <v-text-field v-model="icon"
                          :placeholder="$t('forms.section.edit.icon.search.placeholder')"
                          :label="$t('forms.section.edit.icon.search.label')"
                          prepend-inner-icon="mdi-magnify"></v-text-field>
          </v-col>
        </v-row>
        <v-item-group>
          <v-virtual-scroll
            :bench="1"
            class="d-flex justify-center"
            :items="chunkIcons"
            height="400"
            item-height="80"
          >
            <template v-slot:default="{item}">
              <v-row class="pa-3">
                <v-col cols="1" v-for="(icon) in item" :key="icon">
                  <template v-if="icon.includes('lbm')">
                    <v-item :key="`icon-${icon}`" v-slot="{active, toggle}">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on"
                                   class="d-flex justify-center align-center"
                                   @click="toggle; chooseIcon(icon)" fab>
                              <v-icon large>{{ icon }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ icon.replace('lbm', '').replace(/\-/gi, ' ').replace(/[0-9]/gi, '') }}</span>
                        </v-tooltip>
                    </v-item>
                  </template>
                  <template v-if="icon.includes('mdi')">
                    <v-item :key="`icon-${icon}`" v-slot="{active, toggle}">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on"
                                 class="d-flex justify-center align-center" fab @click="toggle; chooseIcon(icon)">
                            <v-icon v-text="icon" large></v-icon>
                          </v-btn>
                        </template>
                        <span>{{ icon.replace('mdi', '').replace(/\-/gi, ' ') }}</span>
                      </v-tooltip>
                    </v-item>
                  </template>
                  <template v-if="icon.includes('special')">
                      <v-item :key="`icon-${icon}`" v-slot="{active, toggle}">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on"
                                 class="d-flex justify-center align-center" fab @click="toggle; chooseIcon(icon)">
                            <v-icon v-text="icon" large></v-icon>
                          </v-btn>
                        </template>
                        <span>{{ icon.replace('special', '').replace(/\-/gi, ' ') }}</span>
                      </v-tooltip>
                      </v-item>
                    </template>
                </v-col>
              </v-row>
            </template>
          </v-virtual-scroll>
        </v-item-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="$store.getters.GET_LOADING" tile color="primary" @click="submit">{{ $t('buttons.update') }}</v-btn>
        <v-btn dark tile color="grey" @click="closeIconChoosing">{{ $t('buttons.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import * as icons from '@/static/icons.json'
import { SectionInterface } from '@/interfaces/section.interface'

export default {
  name: 'EditSectionIconDialogComponent',
  data () {
    return {
      open: false,
      section: new SectionInterface(),
      type: 'update',
      icon: null,
      icons: icons.mdi,
      pack: 'mdi'
    }
  },
  mounted () {
    this.$root.$on('open_icon_dialog', (section, type) => {
      this.section = section
      this.type = type
      this.open = true
    })
    this.$root.$on('close_icon_dialog', () => {
      this.closeIconChoosing()
    })
  },
  methods: {
    closeIconChoosing () {
      this.open = false
    },
    chooseIcon (icon) {
      this.section.icon = icon
    },
    setPack (pack) {
      switch (pack) {
        case 'mdi':
          this.pack = 'mdi'
          this.icons = []
          this.icons = icons.mdi
          break
        case 'kitchen':
          this.pack = 'kitchen'
          this.icons = []
          this.icons = icons.kitchen
          break
        case 'special':
          this.pack = 'special'
          this.icons = []
          this.icons = icons.special
          break
      }
    },
    submit () {
      switch (this.type) {
        case 'create':
          this.$root.$emit('create_section', this.section)
          break
        default:
          this.$root.$emit('update_section', this.section)
          break
      }
    }
  },
  computed: {
    chunkIcons () {
      if (this.icon) {
        const search = this.icons.filter((item) => {
          return item.includes(this.icon)
        })
        return _.chunk(search, 12)
      } else {
        return _.chunk(this.icons, 12)
      }
    }
  }
}
</script>

<style scoped>

</style>
