<template>
<v-navigation-drawer
  color="primary"
  v-model="show"
  width="330"
  right
  fixed
  temporary>
  <locales-component/>
</v-navigation-drawer>
</template>

<script>
export default {
  name: 'AppSideMenuComponent',
  computed: {
    show: {
      get () {
        return this.$store.getters.GET_DRAWER
      },
      set (value) {
        this.$store.dispatch('setDrawer', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
