<template>
  <v-dialog v-model="show"
            scrollable
            :width="$vuetify.breakpoint.width / 1.4"
            :height="$vuetify.breakpoint.height / 1.4"
            content-class="rounded-0"
            @click:outside="close">
    <v-card tile>
      <v-card-title>
        <span>Add new recipe</span>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="addNewObserver" v-slot="{ passes }">
          <v-form @submit.prevent="passes( submit )">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ml-0 pl-0">
                <v-text-field label="Add title"
                              placeholder="Please add recipe title"
                              clearable
                              v-model="form.title"></v-text-field>
                <v-dialog
                  content-class="rounded-0"
                  ref="dialog"
                  v-model="timeModal"
                  :return-value.sync="form.time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.time"
                      label="Add preparation time"
                      placeholder="Please set preparation time"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    class="rounded-0"
                    no-title
                    v-if="timeModal"
                    v-model="form.time"
                    format="24hr"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="timeModal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(form.time)"
                    >
                      Set
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
                <v-file-input ref="images"
                              @change="chooseFiles"
                              label="Add recipe images"
                              placeholder="Please add recipe images"
                              prepend-icon=""
                              multiple
                              accept="image/*"
                              v-model="form.images">
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" label small color="warning">
                      {{ text }}
                    </v-chip>
                    <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
                          + {{ form.images.length - 2 }} {{ form.images.length - 2 > 1 ? 'images' : 'image' }}
                        </span>
                  </template>
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-image-multiple</v-icon>
                  </template>
                </v-file-input>
                <div class="my-5">
                  <div v-for="(ingredient, i) in ingredients"
                       class="d-lg-flex align-lg-center justify-lg-space-between"
                       :key="i">
                    <autocomplete-ingredient-search-component :ingredient="ingredient" :index="i"/>
                  </div>
                  <div class="d-flex align-center mb-lg-4">
                    <v-btn @click="addIngredient" fab x-small color="success">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <label class="ml-2">Add ingredient</label>
                  </div>
                </div>
                <v-divider class="mt-n1"></v-divider>
                <div class="my-5">
                  <div v-for="(step, i) in steps"
                       class="d-lg-flex align-lg-center justify-lg-space-between"
                       :key="i">
                    <v-textarea
                      label="Add step description"
                      placeholder="Please add description for every step of preparation"
                      v-model="step.step">
                    </v-textarea>
                    <v-btn class="ml-lg-3"
                           @click="removeStep(i)" fab x-small color="error">
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex align-center mb-lg-4">
                    <v-btn @click="addStep" fab x-small color="success">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <label class="ml-2">Add step</label>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="mt-5">
                  <v-select :items="group"
                            v-model="form.group"
                            label="Choose recipe group"
                            placeholder="Please choose recipe group"></v-select>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile
                     color="error"
                     type="button" @click="cancel">Cancel</v-btn>
              <v-btn tile
                     :loading="$store.getters.GET_LOADING"
                     color="success"
                     type="submit">Add new</v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppAddRecipeDialogComponent',
  data () {
    return {
      timeModal: false,
      searchParams: {
        list: [],
        loading: false
      },
      form: {
        time: null,
        steps: [],
        images: [],
        attachments: [],
        ingredients: []
      },
      group: [
        {
          text: 'Fish',
          value: 'fish'
        },
        {
          text: 'Meat',
          value: 'meat'
        },
        {
          text: 'Vegetables',
          value: 'vegetables'
        },
        {
          text: 'Fruits',
          value: 'fruits'
        },
        {
          text: 'Soup',
          value: 'soup'
        }
      ]
    }
  },
  mounted () {
    this.$root.$on('removeIngredient', (index) => {
      this.form.ingredients.splice(index, 1)
    })
  },
  methods: {
    close () {
      this.$store.dispatch('setRecipeDialog', false)
      this.form = this.$worker.resetForm(this.form)
    },
    submit () {
      this.$store.dispatch('setLoading', true)
      this.$http.post('api/v2/auth/recipe', this.form)
        .then(response => {
          this.$store.dispatch('setRecipes', response.data.recipes)
          this.close()
        })
        .finally(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
    cancel () {
      this.$store.dispatch('setRecipeDialog', false)
    },
    addIngredient () {
      this.form.ingredients.push({
        name: '',
        amount: null,
        kind: null
      })
    },
    addStep () {
      this.form.steps.push(
        {
          step: ''
        }
      )
    },
    removeIngredient (index) {
      this.form.ingredients.splice(index, 1)
    },
    removeStep (index) {
      this.form.steps.splice(index, 1)
    },
    chooseFiles (files) {
      files.forEach(file => {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.form.attachments.push(
            {
              image: reader.result
            }
          )
        }
        reader.readAsDataURL(file)
      })
    }
  },
  computed: {
    show () {
      return this.$store.getters.GET_RECIPE_DIALOG
    },
    steps () {
      return this.form.steps
    },
    ingredients () {
      return this.form.ingredients
    }
  }
}
</script>

<style scoped>

</style>
