<template>
  <v-container class="fill-height pa-0 ma-0" fluid>
    <v-row class="ma-0 fill-height"
           :style="$vuetify.breakpoint.lgOnly ? 'padding: 24px 24px 24px 200px' : 'padding: 24px 24px 24px 250px'">
      <v-col cols="12" class="d-flex pt-0 ma-0 justify-center align-baseline fill-height">
        <transition
          enter-active-class="transition-ease-in-out"
          leave-active-class="transition-ease-in-out">
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>
    <v-navigation-drawer
      class="elevation-6"
      :style="$vuetify.breakpoint.lgAndUp ? 'z-index: 0' : 'z-index: 2'"
      :value="true"
      :width="$vuetify.breakpoint.lgOnly ? 200 : 250"
      :mini-variant="$vuetify.breakpoint.mobile"
      permanent
      hide-overlay
      color="primary"
      fixed>
      <v-list-item two-line style="padding-top: 70px">
        <v-list-item-avatar size="20" color="secondary">
          <v-icon small color="primary">mdi-account-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="white--text">{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle class="white--text">{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item @click="$router.push({name: 'admin.dashboard'})">
        <v-list-item-icon class="d-flex justify-center mr-3">
          <v-icon small>mdi-view-dashboard-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="white--text"
                             v-if="$vuetify.breakpoint.lgAndUp">{{ $t('dashboard') }}</v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({name: 'admin.menu'})">
        <v-list-item-icon class="d-flex justify-center mr-3">
          <v-icon small>mdi-menu</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="white--text"
                             v-if="$vuetify.breakpoint.lgAndUp">{{ $t('menu') }}</v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({name: 'admin.footer'})">
        <v-list-item-icon class="d-flex justify-center mr-3">
          <v-icon small>mdi-page-layout-footer</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="white--text"
                             v-if="$vuetify.breakpoint.lgAndUp">{{ $t('footer') }}</v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({name: 'admin.images'})">
        <v-list-item-icon class="d-flex justify-center mr-3">
          <v-icon small>mdi-image-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="white--text"
                             v-if="$vuetify.breakpoint.lgAndUp">{{ $t('images') }}</v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({name: 'admin.image-editor'})">
        <v-list-item-icon class="d-flex justify-center mr-3">
          <v-icon small>mdi-image-edit-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="white--text"
                             v-if="$vuetify.breakpoint.lgAndUp">{{ $t('editor') }}</v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="logout">
        <v-list-item-icon class="d-flex justify-center mr-3">
          <v-icon small>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="white--text"
                             v-if="$vuetify.breakpoint.lgAndUp">{{ $t('buttons.logout') }}</v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
    <side-menu-component/>
    <confirmation-dialog-component/>
    <add-new-section-dialog-component/>
    <edit-section-icon-dialog-company/>
    <edit-section-image-dialog-component/>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',
  data () {
    return {
      menu: []
    }
  },
  mounted () {
    this.$root.$on('remove-section', (id) => {
      this.$store.dispatch('setLoading', true)
      this.$http.delete(`api/v2/auth/sections/${id}`)
        .then((response) => {
          this.$root.$emit('update-after-remove', response.data.sections)
          this.$store.dispatch('setConfirmation', { show: false, action: null }).then(() => {
            this.$store.dispatch('setLoading', false)
          })
        })
    })
  },
  methods: {
    logout () {
      this.$http.post('api/v2/auth/logout')
        .then(response => {
          this.$store.dispatch('setAlert', { show: true, type: 'success', message: response.data.message })
          this.$store.dispatch('setAuthorization', null)
          this.$store.dispatch('setUser', null)
          this.$router.push({ name: 'main' })
        })
    }
  },
  computed: {
    user () {
      return this.$store.getters.GET_USER ? this.$store.getters.GET_USER : {}
    }
  }
}
</script>
