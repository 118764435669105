/* eslint-disable no-prototype-builtins */

import axios from 'axios'
import store from '@/store'
import router from '@/router'

const $http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  },
  withCredentials: false,
  crossDomain: true,
  params: {}
})

$http.interceptors.request.use(request => {
  request.headers['Accept-Language'] = document.documentElement.lang
  if (request.url.includes('auth')) {
    store.dispatch('setLoading', true).then()
    request.headers.Authorization = store.getters.GET_AUTHORIZATION._token
    request.headers.Accept = 'application/json'
    if (request.url.includes('sections')) {
      if (request.method === 'post' || request.method === 'POST') {
        request.data.section_content = request.data.content
        delete request.data.content
      }
    }
  }
  return request
})

$http.interceptors.response.use(response => {
  if (response.data.hasOwnProperty('_token')) {
    store.dispatch('setUser', response.data.user).catch()
    store.dispatch('setAuthorization', {
      _token: response.data._token,
      _expires_at: response.data._expires_at
    }).catch()
  }
  if (response.data.hasOwnProperty('message')) {
    store.dispatch('setAlert', { show: true, type: 'success', message: response.data.message }).then()
  }
  store.dispatch('setLoading', false).then()
  return response
}, error => {
  store.dispatch('setLoading', false).then()
  if (error.response) {
    switch (error.response.status) {
      case 401:
        store.dispatch('setAlert', { show: true, type: 'error', message: error.response.data.error })
          .then(() => {
            store.dispatch('setAuthorization', null).catch(() => {})
            store.dispatch('setUser', null).catch(() => {})
            router.push({ name: 'main' }).catch(() => {})
          })
        break
      case 409:
      case 500:
        store.dispatch('setAlert', { show: true, type: 'error', message: error.response.data.error }).then()
        break
      default:
        break
    }
  }
})

const HttpService = {
  install (Vue) {
    Vue.prototype.$http = $http
  }
}

export default (HttpService)
