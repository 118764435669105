/* eslint-disable camelcase */
import Image from '@/interfaces/image.interface'

export class SectionInterface {
  id = null
  name = null
  route = null
  icon = null
  children = []
  image_id = null
  image = new Image()
  food = null
  sectionable_id = null
  content = null
  created_at = null
}
