<template>
  <v-col cols="12" class="ma-0 pr-0 pl-0 pt-0 lbm_dashboard" :style="`min-height: ${$vuetify.breakpoint.height * 1.2}px`">
    <v-card flat class="lbm_dashboard__statistic"
            :max-width="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.width - 328 : $vuetify.breakpoint.width - 50"
            :min-height="$vuetify.breakpoint.height / 1.1">
      <v-card :loading="pending.views" class="lbm_dashboard__views">
        <v-card-title class="d-flex justify-start font-weight-bold">
          <span>{{ $t('statistic.views.title')}} {{ views ? views : $t('day') }}</span>
        </v-card-title>
        <v-card-text id="viewsPerPeriodsChart" :style="`height: ${$vuetify.breakpoint.height / 2.8}px`"></v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-0">
            <v-col cols="5" class="ma-0 pa-0">
              <v-select
                @change="getAnalyticsPerPeriod('views')"
                :items="dates"
                item-text="title"
                item-value="value"
                v-model="date.views"
                :label="$t('choosePeriod')"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card :loading="pending.data" min-height="100%"  class="lbm_dashboard__traffic">
        <v-card-title class="d-flex justify-start font-weight-bold">
          <span>{{ $t('statistic.traffic.title')}}</span>
        </v-card-title>
        <v-card-text :style="`height: ${$vuetify.breakpoint.height / 2.5}px`" id="trafficChart"></v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-0">
            <v-col cols="5" class="ma-0 pa-0">
              <v-select
                @change="getAnalyticsPerPeriod('traffic')"
                :items="dates"
                item-text="title"
                item-value="value"
                v-model="date.traffic"
                :label="$t('choosePeriod')"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-card>
    <v-card flat class="lbm_dashboard__geo-and-table" :style="`grid-auto-rows: minmax(${$vuetify.breakpoint.height / 2}px, max-content)`">
      <v-card :max-width="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.width - 328 : $vuetify.breakpoint.width - 50"
              :loading="pending.map" class="lbm_dashboard__geo">
        <v-card-title class="d-flex justify-start font-weight-bold" style="position: absolute; z-index: 2">
          <span>{{ $t('statistic.views.title')}}{{ map ? map : $t('day') }}</span>
        </v-card-title>
        <v-card-text class="pa-0" style="height: 100%" id="chartMap"></v-card-text>
        <v-card-actions style="position: absolute; bottom: 0; z-index: 2;">
          <v-row class="ma-0">
            <v-col cols="10" class="ma-0">
              <v-select
                @change="getAnalyticsPerPeriod('map')"
                :items="dates"
                item-text="title"
                item-value="value"
                v-model="date.map"
                :label="$t('choosePeriod')"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card :loading="pending.sources" class="lbm_dashboard__traffic-detailed">
        <v-card-title class="d-flex justify-start font-weight-bold">
          <span>{{ $t('statistic.traffic.sources')}}</span>
        </v-card-title>
        <v-card-text :style="`min-height: ${$vuetify.breakpoint.height / 2.415}px`" id="trafficSourcesChart"></v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-0">
            <v-col cols="5" class="ma-0 pa-0">
              <v-select
                @change="getAnalyticsPerPeriod('detailed')"
                :items="dates"
                item-text="title"
                item-value="value"
                v-model="date.detailed"
                :label="$t('choosePeriod')"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-card>
    <v-card class="lbm_dashboard__table">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          :style="`width: ${$vuetify.breakpoint.width / 3.3}px`"
          v-model="search"
          solo
          prepend-inner-icon="mdi-magnify"
          :label="$t('searches.children.label')"
          :placeholder="$t('searches.children.placeholder')"
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
          :calculate-widths="true"
          :max-height="pending.table ? 100 : $vuetify.breakpoint.height * 0.77"
          fixed-header
          :loading="pending.table"
          @click:row="toSection"
          @item-expanded="expand"
          show-expand
          :expanded="expanded"
          :single-expand="true"
          :headers="headers"
          :items="sections"
          :items-per-page="25"
          :footer-props="{itemsPerPageOptions: [5, 10, 15, 20, 25, 50, 100, -1]}"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td class="pa-0 elevation-0" :colspan="headers.length">
              <v-card class="scrollable pa-0" :height="$vuetify.breakpoint.height / 2" tile flat>
                <v-card-title>
                  <v-btn @click="translateSingleToAllLanguages(item.id)" color="primary" dark>{{ $t('buttons.translate') }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    :style="`width: ${$vuetify.breakpoint.width / 7.3}px`"
                    solo
                    prepend-inner-icon="mdi-magnify"
                    v-model="search"
                    :label="$t('searches.children.label')"
                    :placeholder="$t('searches.children.placeholder')"
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-text-field>
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-row class="pa-0 ma-0">
                    <v-col :cols="active ? 5 : 12" style="position: relative">
                      <v-card class="pa-0" tile flat height="500"
                              style="position: absolute; width: 98%; top: 1%; bottom: 1%; left: 1%; overflow-y: scroll">
                        <v-treeview
                          hoverable
                          expand-icon="mdi-chevron-down"
                          activatable
                          item-key="name"
                          :search="search"
                          :items="item.children"
                          :filter="filter"
                        >
                          <template v-slot:prepend="{ open }">
                            <v-icon>{{ open ? 'mdi-tray' : 'mdi-tray-full' }}</v-icon>
                          </template>
                          <template v-slot:label="{ item }">
                            <div @click="getContent(item)">{{ item.name }}</div>
                          </template>
                          <template v-slot:append="{ item }">
                            <v-btn color="primary" dark fab x-small @click="toSection(item)">
                              <v-icon small>mdi-arrow-right</v-icon>
                            </v-btn>
                          </template>
                        </v-treeview>
                      </v-card>
                    </v-col>
                    <template v-if="Object.keys(active).length > 0">
                      <v-col cols="7" style="position: relative">
                        <v-card style="position: absolute; width: 98%; top: 4%; bottom: 2%; right: 2%; overflow-y: scroll" :loading="pending.content" min-height="400" max-width="100%" class="pa-0">
                          <v-card-text v-html="active.content"></v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </td>
          </template>
          <template v-slot:item.route="{ item }">
            <span>{{ $vuetify.breakpoint.lgOnly ? $options.filters.truncate(item.route, 20) : item.route}}</span>
          </template>
          <template v-slot:item.name="{ item }">
            <span>{{ $vuetify.breakpoint.lgOnly ? $options.filters.truncate(item.name, 13) : item.name}}</span>
          </template>
          <template v-slot:item.translated="{ item }">
            <div class="text-center">
              <template v-if="item['translated'] === 1">
                <v-icon color="success">mdi-checkbox-blank-circle</v-icon>
              </template>
              <template v-else>
                <v-icon color="error">mdi-checkbox-blank-circle</v-icon>
              </template>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
/* eslint-disable camelcase,no-unused-vars */
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import options from '@/static/chartsOptions.json'

export default {
  name: 'StatisticComponent',
  data () {
    return {
      search: null,
      pending: {
        table: true,
        content: true,
        map: true,
        data: true,
        sources: true,
        views: true
      },
      view: 'table',
      itemsPerPageArray: [4, 8, 12],
      page: 1,
      itemsPerPage: 8,
      sections: [],
      expanded: [],
      caseSensitive: false,
      active: {},
      date: {
        map: null,
        views: null,
        traffic: null,
        detailed: null
      },
      period: {
        views: null,
        map: null
      },
      series: []
    }
  },
  mounted () {
    this.getSections()
    this.getAnalytics()
    this.$root.$on('reload', () => {
      Object.keys(this.pending).forEach(key => {
        this.pending[key] = true
      })
      this.getAnalytics()
      this.getSections()
    })
  },
  methods: {
    getSections () {
      this.pending.table = true
      this.$http.get('api/v2/auth/sections')
        .then(response => {
          this.pending.table = false
          this.sections = response.data.sections
        })
        .catch(() => {
          this.pending.table = false
        })
    },
    setNoImage (source) {
      return source || require('@/assets/image-off-outline.svg')
    },
    toSection (item) {
      window.sessionStorage.setItem('section_id', item.id)
      this.$router.push({ name: 'admin.sections', params: { id: item.id } })
    },
    getContent (item) {
      this.pending.content = true
      this.$http.get(`api/v2/auth/content/${item.id}`)
        .then(response => {
          this.active = response.data.content
          this.pending.content = false
        })
        .catch(() => {
          this.pending.content = false
        })
    },
    removeSection (item) {
      this.$store.dispatch('setConfirmation', { show: true, id: item.id, action: 'remove-section' })
    },
    expand (item) {
      if (!item.value) {
        this.active = {}
      }
    },
    async getAnalytics () {
      await this.$http.get('api/v2/auth/analytics/1')
        .then((response) => {
          this.buildMapChart(this.prepareMapData(response))
          this.buildTrafficChart(['views', 'source'], this.prepareTrafficData(response), 'trafficChart')
          this.buildTrafficChart(['views', 'source'], this.prepareTrafficSourceData(response), 'trafficSourcesChart')
          this.buildViewsChart(response.data.views)
        })
    },
    buildTrafficChart (fields, data, id) {
      am4core.options.autoDispose = true
      am4core.options.queue = true
      am4core.useTheme(am4themes_animated)

      const chart = am4core.create(id, am4charts.PieChart)
      const pieSeries = chart.series.push(new am4charts.PieSeries())
      chart.legend = new am4charts.Legend()
      chart.legend.scrollable = true
      chart.legend.position = 'right'
      pieSeries.dataFields.value = fields[0]
      pieSeries.dataFields.category = fields[1]

      pieSeries.labels.template.disabled = true

      chart.innerRadius = am4core.percent(30)
      pieSeries.slices.template.cursorOverStyle = [
        {
          property: 'cursor',
          value: 'pointer'
        }
      ]

      pieSeries.labels.template.radius = 3
      pieSeries.labels.template.padding(0, 0, 0, 0)

      chart.data = data

      chart.events.on('ready', () => {
        this.pending.data = false
        setTimeout(() => { this.pending.sources = false }, 2500)
      })
    },
    buildMapChart (data) {
      const tooltips = {
        tooltip: this.nonZeroTooltip,
        zeroTooltip: this.zeroTooltip
      }
      am4core.options.autoDispose = true
      am4core.options.queue = true
      am4core.useTheme(am4themes_frozen)

      const colorSet = new am4core.ColorSet()

      const chart = am4core.create('chartMap', am4maps.MapChart)
      chart.geodata = am4geodata_worldLow
      chart.projection = new am4maps.projections.Mercator()
      chart.zoomControl = new am4maps.ZoomControl()

      const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
      polygonSeries.exclude = ['AQ']
      polygonSeries.useGeodata = true
      polygonSeries.mapPolygons.template.nonScalingStroke = true

      polygonSeries.tooltip.getFillFromObject = false
      polygonSeries.tooltip.backgroundColor = '#fff'

      polygonSeries.tooltip.label.adapter.add('html', function (text, target) {
        if (target.dataItem && target.dataItem.value === undefined) {
          return tooltips.zeroTooltip
        } else {
          return tooltips.tooltip
        }
      })

      const polygonTemplate = polygonSeries.mapPolygons.template
      polygonTemplate.tooltipHTML = options.mapChart.tooltip
      polygonTemplate.polygon.fillOpacity = 1
      polygonTemplate.fill = colorSet.next()

      const hs = polygonTemplate.states.create('hover')
      hs.properties.fill = am4core.color('#2A73E8')

      polygonSeries.heatRules.push({
        property: 'fill',
        target: polygonSeries.mapPolygons.template,
        min: am4core.color('#81D4FA'),
        max: am4core.color('#01579B'),
        logarithmic: true
      })
      polygonSeries.data = data

      chart.events.on('ready', () => {
        this.pending.map = false
      })
    },
    buildViewsChart (data) {
      am4core.useTheme(am4themes_animated)

      const chart = am4core.create('viewsPerPeriodsChart', am4charts.XYChart)

      data.forEach(item => {
        chart.data.push({
          currentDate: item.currentDate,
          currentValue: item.currentValue,
          previousDate: item.previousDate,
          previousValue: item.previousValue
        })
      })
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.renderer.minGridDistance = 50
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      const series = chart.series.push(new am4charts.LineSeries())

      series.dataFields.valueY = 'currentValue'
      series.dataFields.dateX = 'currentDate'
      series.strokeWidth = 2
      series.minBulletDistance = 10
      series.tooltipText = '[bold]{currentDate}:[/] {currentValue}\n[bold]{previousDate}:[/] {previousValue}'
      series.tooltip.pointerOrientation = 'vertical'

      const series2 = chart.series.push(new am4charts.LineSeries())
      series2.dataFields.valueY = 'previousValue'
      series2.dataFields.dateX = 'currentDate'
      series2.strokeWidth = 2
      series2.strokeDasharray = '3,4'
      series2.stroke = series.stroke

      chart.cursor = new am4charts.XYCursor()
      chart.cursor.xAxis = dateAxis

      chart.events.on('ready', () => {
        this.pending.views = false
      })
    },
    prepareTrafficSourceData (response) {
      const data = []
      Object.keys(response.data.sources).forEach(key => {
        response.data.sources[key].forEach(source => {
          data.push(
            {
              source: this.transformString(source.source),
              views: source.views
            }
          )
        })
      })
      return data
    },
    prepareTrafficData (response) {
      const series = []
      Object.keys(response.data.sources).forEach(key => {
        series.push(
          {
            source: key.replace(/^\w/, (c) => c.toUpperCase()),
            views: response.data.sources[key].length
          }
        )
      })
      return series
    },
    prepareMapData (response) {
      const data = []
      response.data.countries.forEach(item => {
        const view = {
          id: item.iso,
          name: item.country,
          value: parseInt(item.views)
        }
        data.push(view)
      })
      return data
    },
    transformString (value) {
      if (value.includes('.')) {
        return value
      }
      if (value === '(direct)') {
        return 'Direct'
      } else {
        return value.replace(/^\w/, (c) => c.toUpperCase())
      }
    },
    async getAnalyticsPerPeriod (metric) {
      switch (metric) {
        case 'views':
          this.views = this.dates.find(item => item.value === this.date.views).title
          this.pending.views = true
          await this.$http.get(`api/v2/auth/analytics/${this.date.views}`)
            .then((response) => {
              this.buildViewsChart(response.data.views)
            })
          break
        case 'traffic':
          this.pending.data = true
          await this.$http.get(`api/v2/auth/analytics/${this.date.traffic}`)
            .then((response) => {
              this.buildTrafficChart(['views', 'source'], this.prepareTrafficData(response))
            })
          break
        case 'detailed':
          this.pending.sources = true
          await this.$http.get(`api/v2/auth/analytics/${this.date.detailed}`)
            .then((response) => {
              this.buildTrafficSourcesChart(this.prepareTrafficSourceData(response))
            })
          break
        case 'map':
          this.map = this.dates.find(item => item.value === this.date.map).title
          this.pending.map = true
          await this.$http.get(`api/v2/auth/analytics/${this.date.map}`)
            .then((response) => {
              this.buildMapChart(this.prepareMapData(response))
            })
          break
      }
    },
    translateSingleToAllLanguages (id) {
      this.$store.dispatch('setAlert', { show: true, type: 'info', message: 'Please, wait: system is in process with making jobs for translations' })
      this.$http.post('api/v2/auth/translate/available', {
        id: id
      })
    }
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.sections.length / this.itemsPerPage)
    },
    headers () {
      const headers = [
        {
          value: 'name',
          sortable: true,
          visible: true
        },
        {
          value: 'route',
          sortable: true,
          visible: true
        },
        {
          value: 'translated',
          sortable: true,
          visible: true
        },
        {
          value: 'created_at',
          sortable: true,
          visible: !this.$vuetify.breakpoint.lgOnly
        },
        {
          value: 'updated_at',
          sortable: true,
          visible: !this.$vuetify.breakpoint.lgOnly
        },
        { value: 'data-table-expand', visible: true }
      ]
      headers.forEach((header) => {
        if (!header.value.includes('data-table-expand')) {
          header.text = this.$t(`content.tables.table.${header.value}`)
        }
      })
      return headers
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    zeroTooltip () {
      return this.$t('mapChart.zeroValueTooltip')
    },
    nonZeroTooltip () {
      return this.$t('mapChart.tooltip')
    },
    dates () {
      return this.$t('days')
    },
    views: {
      set (value) {
        this.period.views = value.toLowerCase()
      },
      get () {
        return this.period.views
      }
    },
    map: {
      set (value) {
        this.period.map = value.toLowerCase()
      },
      get () {
        return this.period.map
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lbm_dashboard {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-auto-rows: min-content;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  & .lbm_dashboard__table {
    grid-row: 3 / 4;
    grid-column: 1 / -1;
  }
  & .lbm_dashboard__statistic {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-row-gap: 15px;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    & .lbm_dashboard__views {
      position: relative;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    & .lbm_dashboard__traffic {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
  }
  & .lbm_dashboard__geo-and-table {
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    & .lbm_dashboard__geo {
      position: relative;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    & .lbm_dashboard__traffic-detailed {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
  }
}
</style>
