import Vue from 'vue'
import AppBarComponent from '@/components/AppBarComponent'
import AppSideMenuComponent from '@/components/AppSideMenuComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import AppAddFoodDialogComponent from '@/components/AddFoodDialogComponent'
import AppAddRecipeDialogComponent from '@/components/AddRecipeDialogComponent'
import AppAlertComponent from '@/components/AlertComponent'
import AppEditRecipeDialogComponent from '@/components/EditRecipeDialogComponent'
import AppConfirmationDialogComponent from '@/components/ConfirmationDialogComponent'
import AppAutocompleteIngredientSearchComponent from '@/components/AutocompleteIngredientSearchComponent'
import EditSectionImageDialogComponent from '@/components/EditSectionImageDialogComponent'
import EditSectionIconDialogComponent from '@/components/EditSectionIconDialogComponent'
import AddNewSectionDialogComponent from '@/components/AddNewSectionDialogComponent'
import LocalesComponent from '@/components/LocalesComponent'
import VueInfiniteLoading from 'vue-infinite-loading'
import StatisticComponent from '@/components/StatisticComponent'

Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
Vue.component('app-bar-component', AppBarComponent)
Vue.component('side-menu-component', AppSideMenuComponent)
Vue.component('add-food-dialog-component', AppAddFoodDialogComponent)
Vue.component('add-recipe-dialog-component', AppAddRecipeDialogComponent)
Vue.component('edit-recipe-dialog-component', AppEditRecipeDialogComponent)
Vue.component('confirmation-dialog-component', AppConfirmationDialogComponent)
Vue.component('alert-component', AppAlertComponent)
Vue.component('autocomplete-ingredient-search-component', AppAutocompleteIngredientSearchComponent)
Vue.component('edit-section-image-dialog-component', EditSectionImageDialogComponent)
Vue.component('edit-section-icon-dialog-company', EditSectionIconDialogComponent)
Vue.component('add-new-section-dialog-component', AddNewSectionDialogComponent)
Vue.component('locales-component', LocalesComponent)
Vue.component('infinite-loading', VueInfiniteLoading)
Vue.component('statistic-component', StatisticComponent)
