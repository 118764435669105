<template>
  <v-alert v-if="alert"
           class="lbm_alert"
           v-model="alert.show"
           tile
           :type="alert.type"
           mode="out-in"
           dismissible
           transition="slide-x-reverse-transition">
    <span>{{ alert.message }}</span>
    <template v-slot:close>
      <v-btn @click="close" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: 'AppAlertComponent',
  watch: {
    alert: function () {
      if (this.alert) {
        setTimeout(() => {
          this.close()
        }, 5000)
      }
    }
  },
  methods: {
    close () {
      this.$store.dispatch('setAlert', false)
    }
  },
  computed: {
    alert () {
      return this.$store.getters.GET_ALERT
    }
  }
}
</script>
