<template>
  <v-row class="pa-6"
         style="position: absolute; bottom: 0; width: 100%">
    <v-col class="d-flex justify-space-between align-center" cols="6">
      <p class="body-1 mb-0 white--text">
        <v-icon dark :sixe="24">mdi-translate</v-icon>&nbsp;{{ $t('language') }}
      </p>
    </v-col>
    <v-col class="d-flex justify-space-between align-center" cols="6">
      <div>
        <v-btn dark @click="changeLocale('ru')" icon>RU</v-btn>
      </div>
      <div>
        <v-btn dark @click="changeLocale('de')" icon>DE</v-btn>
      </div>
      <div>
        <v-btn dark @click="changeLocale('en')" icon>EN</v-btn>
      </div>
    </v-col>
    <v-col cols="12" class="d-flex justify-end align-center">
      <small class="mr-1 white--text">Developed by</small>
      <a style="text-decoration: none"
         class="white--text"
         target="_blank"
         href="https://northitgroup.com"><small>NORTH IT GROUP</small></a>
    </v-col>
  </v-row>
</template>

<script>
import { localize } from 'vee-validate'
export default {
  name: 'LocalesComponent',
  mounted () {
    this.changeLocale(this.$cookies.isKey('app_lang') ? this.$cookies.get('app_lang') : 'de')
  },
  methods: {
    changeLocale (lang) {
      this.$cookies.set('app_lang', lang, 86400 * 365)
      this.$vuetify.lang.current = lang
      this.$root.$i18n.locale = lang
      import('vee-validate/dist/locale/' + lang).then(locale => {
        localize(lang, locale)
        document.documentElement.lang = lang
      })
      this.$http.get(`api/v2/change-locale/${lang}`)
        .then(() => {
          this.$root.$emit('reload')
        })
    }
  }
}
</script>

<style scoped>

</style>
