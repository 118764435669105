import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import ru from 'vuetify/es5/locale/ru'
import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'

const vuetify = new Vuetify()

Vue.use(Vuetify)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})
export default new Vuetify({
  lang: {
    locales: {
      ru, en, de
    },
    current: 'ru'
  },
  theme: {
    themes: {
      light: {
        primary: '#40A226',
        secondary: '#FFE400',
        accent: '#FFB100',
        error: '#B53B00'
      }
    }
  }
})
