<template>
  <v-app>
    <alert-component/>
    <app-bar-component/>
    <v-main>
      <v-container fluid class="ma-0 pa-0 fill-height">
        <transition>
          <router-view/>
        </transition>
      </v-container>
    </v-main>
    <v-overlay :style="`height: ${$vuetify.breakpoint.height}px; position: fixed; top: 0; left: 0`"
               v-model="overlay" opacity="1" absolute color="primary" class="d-flex justify-center align-center">
      <v-img
        alt="Lebensmittel Logo"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="@/assets/logo.svg"
        width="240"
      />
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {

    }
  },
  computed: {
    overlay () {
      return this.$store.getters.GET_OVERLAY
    }
  }
}
</script>
